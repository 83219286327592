import joinClasses from "helpers/joinClasses";
import Button from ".";

export function PrimaryButton({ className, children, onClick, reverse = false, ...props }) {
  return (
    <button
      className={joinClasses("flex items-center gap-2 transition-all duration-300 ease-in-out font-bebas-regular text-lg transform  transition-all duration-300 ease-in-out",
        reverse ? "hover:-translate-x-2" : "hover:translate-x-2",

        className)}
      onClick={onClick}
      {...props}
    >
      {reverse && <img src="/assets/icons/arrow.svg" alt="arrow"
        className="transform rotate-180"
      />}
      <span className="mt-1 tracking-widest">{children}</span>
      {!reverse && <img src="/assets/icons/arrow.svg" alt="arrow"
        className=""
      />}
    </button>
  );
}
