import {
  Checkbox,
  PrimaryButton,
  Radio,
  SecondaryButton,
  Textarea,
} from "components/atoms";
import { CheckboxImage } from "components/atoms/inputs/checkboxImage";
import { RadioImage } from "components/atoms/inputs/radioImage";
import { RadioLevel } from "components/atoms/inputs/radioLevel";
import { QuestionTemplate } from "components/modecules";
import joinClasses from "helpers/joinClasses";
import { useEffect, useState } from "react";
import questions from "utils/questions";

export default function Questions({ show = false, onBack = () => { } }) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isAwnserd, setIsAwnserd] = useState(false);

  const [awnseredQuestions, setAwnseredQuestions] = useState([]);

  const handleNext = (event) => {
    event.preventDefault();
    setCurrentQuestion(currentQuestion + 1);
    const newAwnseredQuestions = [...awnseredQuestions, currentQuestion];
    setAwnseredQuestions(newAwnseredQuestions);
    if (newAwnseredQuestions.includes(currentQuestion + 1)) {
      setIsAwnserd(true);
    } else {
      setIsAwnserd(false);
    }
    window.scrollTo(0, 0);
  };

  const handlePrevious = (event) => {
    event.preventDefault();
    if (currentQuestion === 0) {
      onBack();
      return;
    }
    setIsAwnserd(true);
    setCurrentQuestion(currentQuestion - 1);
    window.scrollTo(0, 0);
  };

  const handleAwnser = (event) => {
    setIsAwnserd(true);
    window.scrollTo(0, document.body.scrollHeight);
  };

  const getDescription = (type) => {
    switch (type) {
      case "checkbox":
        return "Plusieurs réponses possibles";
      case "radio":
        return "Une seule réponse possible";
      case "radio-image":
        return "Une seule réponse possible";
      case "checkbox-image":
        return "Plusieurs réponses possibles";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (questions[currentQuestion].bonus) {
      setIsAwnserd(true);
    }
  }, [currentQuestion]);

  return (
    <div className={show ? "flex min-h-screen" : "hidden"}>
      {questions.map((question, index) => {

        if (question.type === "end-page") {
          return (
            <div className={joinClasses("flex-col items-center justify-center w-full px-5", currentQuestion === questions.length - 1 ? "flex" : "hidden"
            )}>
              <img src="/assets/brand/logo.svg" alt="logo" className="mb-20" />
              <div className="flex justify-center items-center flex-col w-fit border-b border-dotted border-secondary pb-4 mb-5">
                <img src="/assets/brand/quiz-logo.svg" alt="quiz" className="w-[450px]" />
                <h1 className="text-5xl tracking-[15px]	font-bebas-bold uppercase mt-8 text-center">Santé Sécurité</h1>
              </div>
              <p className="font-bebas-regular uppercase text-xl tracking-[5px]">DU 02 AU 30 JUIN 2023</p>
              <h1 className="font-hurme-bold text-3xl text-center mt-10">
                Merci d’avoir participé
              </h1>
              <h1 className="font-hurme-bold text-3xl text-center mt-5 max-w-[550px]">
                Cliquez sur terminer pour valider votre participation
              </h1>
              <div className="relative mt-10">
                <div
                  className="w-[200px] h-[60px] bg-primary rounded-full"
                />
                <button
                  type="submit"
                  className="bg-transparent border border-solid border-secondary tracking-widest text-secondary font-courrier rounded-full flex justify-center items-center w-[200px] h-[60px] absolute top-2 left-1 font-bold transition-all hover:left-0 hover:top-0 text-lg"
                >Terminer</button>
              </div>
              {/* <p className="font-hurme-regular mt-10 text-center">Vos résultats vous seront communiqués ultérieurement</p> */}
            </div>
          )
        }

        return (
          <QuestionTemplate
            question={question}
            section={question.section}
            active={index === currentQuestion}
            position={currentQuestion + 1}
            key={index}
          >
            <div dangerouslySetInnerHTML={{ __html: question.pretitre }}></div>
            <h1 className="font-hurme-bold text-xl">
              {question.title}
            </h1>
            {question.type === "radio-illustration" ? (
              <div className="flex flex-col md:flex-row items-start mt-5">
                <img
                  src={"/assets/questions/" + question.illustration}
                  alt={question.title}
                  className="w-1/2 object-contain"
                />
                <div className="">
                  <h1 className="font-hurme-bold text-xl">
                    {question.question}
                  </h1>
                  <p className="mt-5 font-hurme-semibold text-[14px] opacity-50 mb-5">
                    Une seule réponse possible
                  </p>
                  <div>
                    {question.answers.map((answer, index) => (
                      <Radio
                        type="radio"
                        name={question.name}
                        label={answer.label}
                        value={answer.value}
                        onChange={handleAwnser}
                        key={question.name + index}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ) : (<>

              {question.subtitle && <p className="mt-2 font-hurme-semibold text-[14px] opacity-50 mb-5">
                {question.subtitle}
              </p>}
              <p className="mt-5 font-hurme-semibold text-[14px] opacity-50 mb-5">
                {getDescription(question.type)}
              </p>
              <div className="overflow-x-auto md:overflow-x-visible">
                {question.type === "radio-image" ?
                  <RadioImage
                    inputs={question.answers}
                    onChange={handleAwnser}
                    name={question.name}
                  /> : question.type === "checkbox-image" ?
                    <CheckboxImage
                      inputs={question.answers}
                      onChange={handleAwnser}
                      name={question.name}
                    />
                    : question.type === "radio-level" ?
                      <RadioLevel
                        subtitle={question.subtitle}
                        inputs={question.answers}
                        min={question.min}
                        max={question.max}
                        onChange={handleAwnser}
                      />
                      : question.answers ? (
                        question.answers?.map((answer, index) => {
                          switch (question.type) {
                            case "checkbox":
                              return (
                                <Checkbox
                                  type={question.type}
                                  name={question.name}
                                  label={answer.label}
                                  value={answer.value}
                                  onChange={handleAwnser}
                                  key={question.name + index}
                                />
                              );
                            case "radio":
                              return (
                                <Radio
                                  type={question.type}
                                  name={question.name}
                                  label={answer.label}
                                  value={answer.value}
                                  onChange={handleAwnser}
                                  key={question.name + index}
                                />
                              );

                            case "radio-level":
                              return null

                            default:
                              return null;
                          }
                        })
                      ) : (
                        <Textarea
                          type={question.type}
                          name={question.name}
                          key={question.name + index}
                          placeholder="Écrivez votre réponse ici"
                        />
                      )}
              </div>
            </>)}

            {question.source && (
              <div className="flex items-start mt-10 border-t border-dashed border-secondary pt-7">
                <img src="/assets/icons/source.svg" alt="source" className="inline-block mr-2" />
                <div className="font-hurme-regular">
                  <i className="text-lg">{question.source.citation}</i>
                  <p className="text-sm">{question.source.label}</p>
                </div>
              </div>
            )}
            <PrimaryButton
              onClick={handlePrevious}
              className="absolute -bottom-5 left-0 transform translate-y-full"
              reverse
            >
              Précédent
            </PrimaryButton>
            {isAwnserd && <PrimaryButton
              onClick={handleNext}
              className="absolute -bottom-5 right-0 transform translate-y-full"
            >
              suivant
            </PrimaryButton>}
          </QuestionTemplate>
        )
      })}
    </div>
  );
}
