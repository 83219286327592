import { Route, Routes } from "react-router";

import QuizzPage from "quiz/quiz.page";
import SuccessPage from "quiz/success.page";
import { useEffect } from "react";
import ClosePage from "quiz/close.page";

export default function RootContainer() {
  //  si on est le 1er juillet 2023 à 00h00 et plus
  const isJuly = new Date().getTime() > new Date("2023-07-01T00:00:00").getTime();

  if (isJuly) {
    return (
      <Routes>
        <Route path="/" element={<ClosePage />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          !localStorage.getItem("participation") ? (
            <QuizzPage />
          ) : (
            <SuccessPage />
          )
        }
      />
      {/* <Route path="/reglement" element={<RulesPage />} /> */}
      {/* <Route path="/mentions-legales" element={<LegalNoticePage />} /> */}
      <Route path="/debug-develop" element={<DebugMode />} />
    </Routes>
  );
}

const DebugMode = () => {
  useEffect(() => {
    localStorage.removeItem("participation");
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    window.location.href = "/";
  }, []);
  return <div></div>;
};
