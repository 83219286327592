export default function ClosePage() {

  return (
    <div className="flex flex-col-reverse
     md:flex-row justify-between w-full w-full min-h-screen">
      <div className="flex flex-col justify-center items-center min-w-2/6 w-full md:max-w-[600px]">
        <div
          style={{
            backgroundImage: `url(/assets/questions/welcome1.jpg)`,
          }}
          className="w-full h-full bg-cover bg-right bg-no-repeat"
        />
        <div className="bg-primary w-full px-12 py-7 text-secondary">
          <h1 className="font-courrier text-4xl border-b border-dotted border-secondary pb-2 mb-3">Troubles<br />Musculo-Squelettiques</h1>
          <p className="font-hurme-bold text-lg">Prévenir les risques en adoptant les bonnes postures</p>
        </div>
        <div
          style={{
            backgroundImage: `url(/assets/questions/welcome2.jpg)`,
          }}
          className="w-full h-full bg-cover bg-right bg-no-repeat"
        />
      </div>

      <div className="min-w-4/6 w-full px-10 py-10 md:px-20 md:py-20 text-secondary">
        <img src="/assets/brand/logo.svg" alt="logo" />
        <div className="h-full flex justify-center items-start flex-col mt-10 md:mt-0">
          <div className="flex justify-center items-center flex-col w-fit pb-4 mb-5">
            <img src="/assets/brand/quiz-logo.svg" alt="quiz" className="w-[450px]" />
            <h1 className="text-5xl tracking-[15px]	font-bebas-bold uppercase mt-8">Santé Sécurité</h1>
          </div>
          <div className="font-hurme-semibold text-lg relative px-8 py-10 border border-secondary text-center max-w-xl">
            <div className="w-[50px] h-[50px] bg-primary absolute top-0 left-0 rounded-full transform -translate-x-1/2 -translate-y-1/2" />
            <p>Le quiz est clôturé depuis le vendredi 30 juin 23h59</p>
            <p className="my-2">Les heureux gagnants seront trés prochainement contactés et vous aurez bientôt accès au classement général.</p>
            <p>Merci à tous nos participants !</p>
          </div>
        </div>
      </div>


    </div>
  );
}