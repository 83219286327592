
import "./inputs.css";
import { Radio } from "./radio";

export function RadioLevel({
  subtitle = "",
  inputs = [],
  min = 1,
  max = 5,
  onChange,
  ...props
}) {

  return (
    // tableau d'inputs radio avec un label et un name pour chaque input et un nombre de colonnes égal au max
    <div className="flex flex-col gap-5 min-w-[500px]">
      <div className={`grid grid-cols-[repeat(6,1fr)] items-center py-5 px-10 pb-0 gap-5`}>
        <p></p>
        {[...Array(max)].map((_, index) => (
          <p className="text-center" key={index}>{index + 1}</p>
        ))}
      </div>
      {inputs.map((input, index) => (
        <div className={`grid grid-cols-[repeat(6,1fr)] gap-5 items-center odd:bg-primary/25 py-5 px-10 relative`}
          key={index}>
          <p>{input.label}</p>
          {[...Array(max)].map((_, index) => (
            <label className="block custom-radio-level-container">
              <input
                className={"custom-radio-level"}
                type="radio"
                onChange={onChange}
                value={index + 1}
                name={input.name}
              />
            </label>

          ))}
        </div>
      ))}
    </div>
  );
}