import joinClasses from "helpers/joinClasses";

export function RegistrationTemlate({
    children,
    className,
}) {

    return (
        <div className={joinClasses("flex flex-col md:flex-row justify-between w-full w-full min-h-screen", className)}>
            <div className="flex flex-col justify-center items-center min-w-2/6 w-full md:max-w-[600px] hidden lg:flex">
                <div
                    style={{
                        backgroundImage: `url(/assets/questions/welcome1.jpg)`,
                    }}
                    className="w-full h-full bg-cover bg-right bg-no-repeat"
                />
                <div className="bg-primary w-full px-12 py-7 text-secondary">
                    <h1 className="font-courrier text-4xl border-b border-dotted border-secondary pb-2 mb-3">Troubles<br />Musculo-Squelettiques</h1>
                    <p className="font-hurme-bold text-lg">Prévenir les risques en adoptant les bonnes postures</p>
                </div>
                <div
                    style={{
                        backgroundImage: `url(/assets/questions/welcome2.jpg)`,
                    }}
                    className="w-full h-full bg-cover bg-right bg-no-repeat"
                />
            </div>

            <div className="min-w-4/6 w-full px-5 py-5 md:px-20 md:py-20 text-secondary">
                <img src="/assets/brand/logo.svg" alt="logo" />
                <div className="h-full flex justify-center items-start flex-col mt-10 md:mt-0">
                    {children}
                </div>
            </div>
        </div>
    );
}
