import { Card, SubTitle } from "components/atoms";
import { Template } from "components/modecules";

export default function SuccessPage() {
  return (
    <Template>
      <Card className="m-auto flex flex-col justify-center mt-10">
        <div className="flex flex-col items-center justify-center w-full">
          <img src="/assets/brand/quiz-logo2.svg" alt="quiz" className="w-[400px] mb-10" />
          <h1 className="font-hurme-bold text-3xl text-center">
            Votre réponse a été enregistrée.
          </h1>
          {/* <p className="font-hurme-regular mt-5">Vos résultats vous seront communiqués ultérieurement</p> */}
        </div>

      </Card>
    </Template>
  );
}
