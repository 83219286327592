import joinClasses from "helpers/joinClasses";

export function Card({ children, className, ...props }) {
  return (
    <div
      className={joinClasses(
        "w-full max-w-[744px] w-full bg-white/50 border border-secondary px-10 py-10 transition-all duration-300 ease-in-out relative",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}
