import { Card } from "components/atoms";
import joinClasses from "helpers/joinClasses";
import { useEffect, useState } from "react";

import questions from "utils/questions";

export function QuestionTemplate({
  onNext = () => { },
  active = false,
  section = {},
  question = {},
  position = 1,
  children,
}) {

  if (!question) {
    return null;
  }

  // let total = 0;

  // for (const key in questions) {
  //   if (questions[key].type === "radio-level") {
  //     total += questions[key].answers.length - 1;
  //   } else {
  //     total += 1;
  //   }
  // }

  // const realPosition = position === 4 ? position + 4 : position;
  const total = questions.length - 1;


  return (
    <div className={joinClasses("flex flex-col md:flex-row w-full justify-start gap-10", !active && "hidden")}>
      <div className="flex flex-row-reverse md:flex-col w-full md:w-1/4 md:max-w-[430px]">
        <div className="w-full bg-primary py-10 px-6 md:py-16 md:px-10 max-w-1/4 md:min-h-fit">
          <h1 className="font-courrier font-bold text-2xl md:text-3xl lg:text-6xl">TMS</h1>
          <h2 className="font-hurme-semibold text-md md:text-xl lg:text-3xl mt-2 md:mt-10">{section.title}</h2>
        </div>
        <div
          className="h-full w-1/4 md:w-full bg-no-repeat bg-center bg-cover"
          style={{
            backgroundImage: `url("/assets/questions/${section.image}")`,
          }}
        />
      </div>
      <div className="w-full md:w-3/4 px-5 md:px-10 font-hurme-regular">
        <div>
          <img src="/assets/brand/logo.svg" alt="quiz santé sécurité" className="block mx-auto md:mt-10 mb-20 md:mb-[150px]" />
        </div>
        <Card className="relative mx-auto mb-32">
          <p className="font-hurme-semibold absolute top-0 bg-primary w-fit px-5 pt-2 pb-1 text-secondary text-[20px] leading-[30px] -transform -translate-y-1/2">
            <span className="text-[30px]">
              {position < 10 ? `0${position}` : position}
            </span>
            /{total < 10 ? `0${total}` : total}
          </p>
          {children}
        </Card>
      </div>
    </div >
  )
}
