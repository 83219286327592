import { Card, PrimaryButton, SecondaryButton } from "components/atoms";
import { RegistrationTemlate } from "components/modecules";
import joinClasses from "helpers/joinClasses";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Register({ onNext = () => { }, show = false }) {
  const [step, setStep] = useState(0);
  const [isAwnserd, setIsAwnserd] = useState(false);
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    address: "",
    phone: "",
  });

  const entries = {
    firstname: "entry.666563629",
    lastname: "entry.668854859",
    email: "emailAddress",
    address: "entry.1711193973",
    phone: "entry.1992919417",
  };

  const handleClick = (event) => {
    event.preventDefault();
    onNext();
  };

  useEffect(() => {
    if (
      user.firstname.length >= 1 &&
      user.lastname.length >= 2 &&
      user.email.length >= 5 &&
      user.address.length >= 5 &&
      user.phone.length >= 10
    ) {
      setIsAwnserd(true);
    } else {
      setIsAwnserd(false);
    }
  }, [user]);


  return (
    <RegistrationTemlate className={show ? "block" : "hidden"}>
      <div className={joinClasses("text-secondary", step === 0 ? "block" : "hidden")}>
        <img
          src="/assets/brand/quiz-logo2.svg"
          alt="quiz santé sécurité"
          className="w-[70%] mx-auto mb-16"
        />
        <Card>
          <h1
            className="w-2/3 bg-primary absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-hurme-semibold text-3xl text-secondary text-center py-1 pt-3 uppercase"
          >MODALITÉS</h1>
          <h2 className="font-hurme-bold text-xl mt-8">Obtenez 100% de bonnes réponses pour pouvoir remporter un des 20 lots en jeu.</h2>

          <p className="mt-5 font-hurme-regular leading-8">
            En cas d’égalité les gagnants seront tirés au sort
            pour être départagés.<br />
            Rien ne sert de jouer plusieurs fois, seul votre premier résultat compte !
            <br />
            <br />
            Le quiz, d’une durée d’environ 5 minutes, est accessible du 02 au 30 juin 2023 uniquement aux collaborateurs Axione.
          </p>
        </Card>
        <div className="flex justify-end mt-5">
          <PrimaryButton onClick={(e) => {
            e.preventDefault();
            setStep(1);
          }}>
            Suivant
          </PrimaryButton>
        </div>
      </div>

      <div className={joinClasses("text-secondary", step === 1 ? "block" : "hidden")}>
        <img
          src="/assets/brand/quiz-logo2.svg"
          alt="quiz santé sécurité"
          className="w-[70%] mx-auto mb-16"
        />
        <Card>
          <h1
            className="w-2/3 bg-primary absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-hurme-semibold text-3xl text-secondary text-center py-1 pt-3 uppercase"
          >À gagner</h1>
          <img
            src="/assets/brand/gifts.png"
            alt="cadeaux à gagner"
            className="w-[80%] mx-auto my-7"
          />
        </Card>
        <div className="flex justify-between mt-5">
          <PrimaryButton onClick={(e) => {
            e.preventDefault();
            setStep(0);
          }} reverse>
            Précédent
          </PrimaryButton>
          <PrimaryButton onClick={(e) => {
            e.preventDefault();
            setStep(2);
          }}>
            Suivant
          </PrimaryButton>
        </div>
      </div>

      <div className={joinClasses("text-secondary", step === 2 ? "block" : "hidden")}>
        <img
          src="/assets/brand/quiz-logo2.svg"
          alt="quiz santé sécurité"
          className="w-[70%] mx-auto mb-16"
        />
        <Card>
          <h1
            className="w-2/3 bg-primary absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-hurme-semibold text-3xl text-secondary text-center py-1 pt-3 uppercase"
          >PARTICIPATION</h1>
          <div className="mt-10 flex flex-col gap-5">
            <div className="flex flex-col md:flex-row gap-5">
              <input
                type="text"
                autoComplete="given-name"
                name={entries.firstname}
                onChange={(event) =>
                  setUser({ ...user, firstname: event.target.value })
                }
                placeholder="Prénom"
                className="w-full md:w-1/2 font-hurme-regular leading-1 border border-secondary rounded-[4px] p-3"
              />
              <input
                type="text"
                autoComplete="family-name"
                name={entries.lastname}
                onChange={(event) =>
                  setUser({ ...user, lastname: event.target.value })
                }
                placeholder="Nom"
                className="w-full md:w-1/2 font-hurme-regular leading-1 border border-secondary rounded-[4px] p-3"
              />
            </div>
            <div className="flex flex-col md:flex-row gap-5">
              <input
                type="text"
                name={entries.email}
                onChange={(event) =>
                  setUser({ ...user, email: event.target.value })
                }
                placeholder="Adresse e-mail"
                autoComplete="email"
                className="w-full md:w-1/2 font-hurme-regular leading-1 border border-secondary rounded-[4px] p-3"
              />
              <input
                type="text"
                name={entries.phone}
                onChange={(event) =>
                  setUser({ ...user, phone: event.target.value })
                }
                autoComplete="tel-national"
                placeholder="Numéro de téléphone"
                className="w-full md:w-1/2 font-hurme-regular leading-1 border border-secondary rounded-[4px] p-3"
              />
            </div>
            <div className="flex gap-5">
              <input
                type="text"
                name={entries.address}
                onChange={(event) =>
                  setUser({ ...user, address: event.target.value })
                }
                autoComplete="street-address"
                placeholder="Adresse postale pour envoi de votre lot"
                className="w-full font-hurme-regular leading-1 border border-secondary rounded-[4px] p-3"
              />
            </div>
          </div>
        </Card>
        <div className="flex justify-between mt-5">
          <PrimaryButton onClick={(e) => {
            e.preventDefault();
            setStep(1);
          }} reverse>
            Précédent
          </PrimaryButton>
          {isAwnserd && <PrimaryButton onClick={handleClick}>
            Suivant
          </PrimaryButton>}
        </div>
      </div>

    </RegistrationTemlate >
  )

}
