const questions = [
  {
    id: 1,
    name: "entry.1507151493",
    type: "radio",
    title: "Qu’est ce qu’un TMS ?",
    selectMax: 1,
    section: {
      title: "Comment les caractériser et quelles en sont les conséquences ?",
      image: "cat-1.jpg",
    },
    answers: [
      {
        label: "Trouble Musculo-Squelettique",
        value: "Trouble Musculo-Squelettique",
      },
      {
        label: "Trouble Musculo-Supérieur",
        value: "Trouble Musculo-Supérieur",
      },
      {
        label: "Trouble de la Mobilité et Sécurité",
        value: "Trouble de la Mobilité et Sécurité",
      },
      {
        label: "Trouble de Moyenne Sensorialité",
        value: "Trouble de Moyenne Sensorialité",
      },
      {
        label: "Trouble des Molaires Sensibles",
        value: "Trouble des Molaires Sensibles",
      },
    ],
  },
  {
    id: 123,
    name: "entry.873660125",
    type: "checkbox",
    title: "Le dispositif interne de Reconnaissance de la Qualité de Travailleur Handicapé (RQTH) s’adresse à toute personne en situation de handicap. Il se traduit par des :",
    section: {
      title: "Comment les caractériser et quelles en sont les conséquences ?",
      image: "cat-1.jpg",
    },
    answers: [
      {
        label: "Mesures techniques (aide au financement de prothèses auditives, EPI adaptés…)",
        value: "Mesures techniques (aide au financement de prothèses auditives, EPI adaptés…)",
      },
      {
        label: "Mesures d’aménagement (horaires, ergonomie de poste de travail…)",
        value: "Mesures d’aménagement (horaires, ergonomie de poste de travail…)",
      },
      {
        label: "Mesures d’accompagnement (bilans de compétences, formations…)",
        value: "Mesures d’accompagnement (bilans de compétences, formations…)",
      },
      {
        label: "Mesures financières (tickets restaurants supplémentaires, aide au financement des vacances…)",
        value: "Mesures financières (tickets restaurants supplémentaires, aide au financement des vacances…)",
      },
      {
        label: "Mesures de mobilité (transport adapté…)",
        value: "Mesures de mobilité (transport adapté…)",
      },
    ],
  },
  {
    id: 2,
    type: "radio-level",
    title: "Classez dans l’ordre croissant les parties du corps les plus touchées par les TMS (1 élément bien placé = 1 point)",
    subtitle: "1 = zone la plus touchée et 5 = zone la moins touchée",
    section: {
      title: "Comment les caractériser et quelles en sont les conséquences ?",
      image: "cat-1.jpg",
    },
    min: 1,
    max: 5,
    answers: [
      {
        label: "Épaule",
        name: "entry.829308761",
      },
      {
        label: "Bas du dos",
        name: "entry.1695654675",
      },
      {
        label: "Genou",
        name: "entry.1821663220",
      },
      {
        label: "Coude",
        name: "entry.1436748374",
      },
      {
        label: "Main, poignet, doigts",
        name: "entry.508104252",
      },
    ],
  },
  {
    id: 3,
    name: "entry.1973014442",
    type: "checkbox",
    title: "Quels sont les facteurs qui favorisent les TMS ?",
    selectMax: 1,
    section: {
      title: "Comment les caractériser et quelles en sont les conséquences ?",
      image: "cat-1.jpg",
    },
    answers: [
      {
        label: "Le stress",
        value: "Le stress",
      },
      {
        label: "Le travail assis prolongé sur écran",
        value: "Le travail assis prolongé sur écran",
      },
      {
        label: "Le travail répétitif",
        value: "Le travail répétitif",
      },
      {
        label: "Une activité physique régulière",
        value: "Une activité physique régulière",
      },
      {
        label: "Une activité prolongée en posture contraignante",
        value: "Une activité prolongée en posture contraignante",
      },
      {
        label: "Les antécédents médicaux",
        value: "Les antécédents médicaux",
      },
    ],
  },
  {
    id: 4,
    name: "entry.1490137002",
    type: "radio",
    title: "Les TMS peuvent-ils apparaître en restant immobile ?",
    selectMax: 1,
    section: {
      title: "Comment les caractériser et quelles en sont les conséquences ?",
      image: "cat-1.jpg",
    },
    answers: [
      {
        label: "Oui",
        value: "Oui",
      },
      {
        label: "Non",
        value: "Non",
      },
    ],
  },
  {
    id: 5,
    name: "entry.1877144685",
    type: "radio",
    title: "Au niveau national, en 2019, quel a été le nombre de maladies professionnelles liées aux TMS et reconnues par le régime général* ?",
    subtitle: "*Régime général = Régime de l’assurance maladie des salariés du secteur privé, des agents contractuels de l’états, des étudiants, des chômeurs et bénéficiaires du RSA.",
    selectMax: 1,
    section: {
      title: "Comment les caractériser et quelles en sont les conséquences ?",
      image: "cat-1.jpg",
    },
    answers: [
      {
        label: "64 492",
        value: "64 492",
      },
      {
        label: "44 492",
        value: "44 492",
      },
      {
        label: "24 492",
        value: "24 492",
      },
      {
        label: "Les chiffres ne sont jamais publiés",
        value: "Les chiffres ne sont jamais publiés",
      },
    ],
  },
  {
    id: 6,
    name: "entry.972940815",
    type: "radio",
    title: "Depuis 2019, combien de maladies professionnelles ont été reconnues chez Axione et prises en charge par l’assurance maladie ?",
    selectMax: 1,
    section: {
      title: "Comment les caractériser et quelles en sont les conséquences ?",
      image: "cat-1.jpg",
    },
    answers: [
      {
        label: "5",
        value: "5",
      },
      {
        label: "8",
        value: "8",
      },
      {
        label: "12",
        value: "12",
      },
      {
        label: "15",
        value: "15",
      },
    ],
  },
  {
    id: 124,
    name: "entry.1954678938",
    type: "radio",
    title: "En 2022, quelle est la part des Accidents de Travail Santé* sur le nombre total d’Accidents du Travail ?",
    subtitle: "*Accident de Travail Santé : accident avec et sans arrêt dont les lésions impactent la santé à moyen ou long terme de la victime. Un ATS résulte d'un geste ou d'un effort particulier de la victime (postures de travail, de la manutention, des déplacements, des expositions aux produits chimiques/poussières et des événements affectants la santé mentale).",
    section: {
      title: "Comment les caractériser et quelles en sont les conséquences ?",
      image: "cat-1.jpg",
    },
    answers: [
      {
        label: "7%",
        value: "7%",
      },
      {
        label: "25%",
        value: "25%",
      },
      {
        label: "40%",
        value: "40%",
      },
      {
        label: "60%",
        value: "60%",
      },
    ],
  },
  {
    id: 7,
    name: "entry.1488789046",
    type: "radio",
    title: "Quelles sont les charges maximales qui peuvent être portées par une personne seule chez Axione ?",
    selectMax: 1,
    section: {
      title: "Comment les caractériser et quelles en sont les conséquences ?",
      image: "cat-1.jpg",
    },
    answers: [
      {
        label: "10 kg",
        value: "10 kg",
      },
      {
        label: "15 kg",
        value: "15 kg",
      },
      {
        label: "20 kg",
        value: "20 kg",
      },
      {
        label: "25 kg",
        value: "25 kg",
      },
    ],
  },
  {
    id: 8,
    name: "entry.1192539810",
    type: "radio",
    title: "Les disques intervertébraux sont aux vertèbres :",
    selectMax: 1,
    section: {
      title: "Comment les caractériser et quelles en sont les conséquences ?",
      image: "cat-1.jpg",
    },
    answers: [
      {
        label: "Ce que les lacets sont aux chaussures",
        value: "Ce que les lacets sont aux chaussures",
      },
      {
        label: "Ce que les amortisseurs sont à la voiture",
        value: "Ce que les amortisseurs sont à la voiture",
      },
      {
        label: "Ce que le sel est au poivre",
        value: "Ce que le sel est au poivre",
      },
      {
        label: "Ce que les protections auditives sont au bruit",
        value: "Ce que les protections auditives sont au bruit",
      },
    ],
  },
  {
    id: 11,
    name: "entry.649859296",
    type: "radio-image",
    title: "Quelle est la position la plus optimale pour le dos en cas transport de charge répété ?",
    section: {
      title: "comment s’en prémunir  ?",
      image: "cat-2.jpg",
    },
    answers: [
      {
        label: "question-11-1.png",
        value: "Position 1",
      },
      {
        label: "question-11-2.png",
        value: "Position 2",
      },
    ],
  },
  {
    id: 12,
    name: "entry.45530980",
    type: "checkbox-image",
    title: "Quelles sont les postures à privilégier lorsque l’on travaille plusieurs heures par jour sur un écran ?",
    section: {
      title: "Comment les prévenir ?",
      image: "cat-2.jpg",
    },
    answers: [
      {
        label: "question-12-1.png",
        value: "Posture 1",
      },
      {
        label: "question-12-2.png",
        value: "Posture 2",
      },
      {
        label: "question-12-3.png",
        value: "Posture 3",
      },
      {
        label: "question-12-4.png",
        value: "Posture 4",
      },
      {
        label: "question-12-5.png",
        value: "Posture 5",
      },
      {
        label: "question-12-6.png",
        value: "Posture 6",
      },
    ],
  },
  {
    id: 13,
    name: "entry.109064820",
    type: "checkbox-image",
    title: "Identifiez les situations qui peuvent présenter un risque différé* sur la santé du collaborateur.",
    subtitle: "*Risque différé = risque admettant des conséquences pouvant être ressenties à moyen ou long terme.",
    section: {
      title: "Comment les prévenir ?",
      image: "cat-2.jpg",
    },
    answers: [
      {
        label: "question-13-1.png",
        value: "Situation 1",
      },
      {
        label: "question-13-2.png",
        value: "Situation 2",
      },
      {
        label: "question-13-3.png",
        value: "Situation 3",
      },
      {
        label: "question-13-4.png",
        value: "Situation 4",
      },
      {
        label: "question-13-5.png",
        value: "Situation 5",
      },
      {
        label: "question-13-6.png",
        value: "Situation 6",
      },
    ],
  },
  {
    id: 14,
    name: "entry.74830075",
    type: "checkbox",
    title: "Quelles actions peuvent soulager votre dos au quotidien ?",
    section: {
      title: "Comment les prévenir ?",
      image: "cat-2.jpg",
    },
    answers: [
      {
        label: "Pratiquer une activité physique, pour mieux ressentir la position de son dos",
        value: "Pratiquer une activité physique, pour mieux ressentir la position de son dos",
      },
      {
        label: "Bien utiliser le matériel, communiquer et former les salariés sur les outils",
        value: "Bien utiliser le matériel, communiquer et former les salariés sur les outils",
      },
      {
        label: "Demander à un collègue de réduire la musique dans son casque",
        value: "Demander à un collègue de réduire la musique dans son casque",
      },
      {
        label: "Choisir des engins bien équipés, avec une attention particulière sur le choix des sièges et les dispositifs d’aide à la conduite d'engins",
        value: "Choisir des engins bien équipés, avec une attention particulière sur le choix des sièges et les dispositifs d’aide à la conduite d'engins",
      },
    ],
  },
  {
    id: 15,
    name: "entry.940145433",
    type: "radio",
    title: "Bouygues Construction a mis à disposition une plateforme collaborative et évolutive pour recenser des outils, du matériel et tout autre solution ergonomique appropriée à nos besoins. Comment se nomme-t-elle ?",
    section: {
      title: "Comment les prévenir ?",
      image: "cat-2.jpg",
    },
    answers: [
      {
        label: "La Gourou Plateforme : Une plateforme magique",
        value: "La Gourou Plateforme : Une plateforme magique",
      },
      {
        label: "Le TMS Stadium : Toutes les solutions contre les TMS s’y retrouvent",
        value: "Le TMS Stadium : Toutes les solutions contre les TMS s’y retrouvent",
      },
      {
        label: "L’Ergo’APP : Un besoin ergonomique = une solution pratique",
        value: "L’Ergo’APP : Un besoin ergonomique = une solution pratique",
      },
      {
        label: "L’ENEDIS : ErgoNomie Express’ à destination des Infrastructures Salutaires",
        value: "L’ENEDIS : ErgoNomie Express’ à destination des Infrastructures Salutaires",
      },
    ],
  },
  {
    id: 16,
    name: "entry.744374615",
    type: "checkbox",
    title: "Quels principaux avantages permettent d’apporter la prise en compte de l’ergonomie lors de la conception des postes de travail ?",
    section: {
      title: "Comment les prévenir ?",
      image: "cat-2.jpg",
    },
    answers: [
      {
        label: "Accroître la productivité",
        value: "Accroître la productivité",
      },
      {
        label: "Accroître la résistance aux chocs",
        value: "Accroître la résistance aux chocs",
      },
      {
        label: "Optimiser les conditions de travail pour préserver la santé",
        value: "Optimiser les conditions de travail pour préserver la santé",
      },
      {
        label: "Réduire le stress",
        value: "Réduire le stress",
      },
      {
        label: "Améliorer la satisfaction des travailleurs",
        value: "Améliorer la satisfaction des travailleurs",
      },
    ],
  },
  {
    id: 17,
    name: "entry.1940776042",
    type: "radio",
    pretitre: "<p>Après avoir consulté le médecin traitant, ce dernier a prescrit un traitement avec la prise régulière d’un antalgique.Sur la boîte de ce médicament se trouve ce pictogramme :</p><img src='/assets/questions/question-18-pretitle.png' alt='medicament' width='70px'/><br/>",
    title: "Qu’est-ce que cela signifie ?",
    selectMax: 1,
    section: {
      title: "Les comportements à adopter",
      image: "cat-3.jpg",
    },
    answers: [
      {
        label: "Il est nécessaire de lire attentivement la notice avant reprise de la conduite. Il ne sera pas nécessaire de demander l’avis du médecin",
        value: "Il est nécessaire de lire attentivement la notice avant reprise de la conduite. Il ne sera pas nécessaire de demander l’avis du médecin",
      },
      {
        label: "La prise de ce médicament rend la conduite dangereuse. Il est nécessaire de demander l’avis du médecin avant reprise de la conduite",
        value: "La prise de ce médicament rend la conduite dangereuse. Il est nécessaire de demander l’avis du médecin avant reprise de la conduite",
      },
      {
        label: "La prise du médicament peut remettre en cause la capacité à conduire. Cependant il n’est pas nécessaire de demander l’avis d’un professionnel de santé",
        value: "La prise du médicament peut remettre en cause la capacité à conduire. Cependant il n’est pas nécessaire de demander l’avis d’un professionnel de santé",
      },
      {
        label: "La prise de ce médicament demande une forme physique importante pour maîtriser le véhicule",
        value: "La prise de ce médicament demande une forme physique importante pour maîtriser le véhicule",
      },
    ],
  },
  {
    id: 18,
    name: "entry.1245250374",
    type: "checkbox",
    title: "À la suite d’un effort (un poids soulevé par exemple), une douleur commence à se ressentir du bas du dos vers les membres inférieurs. Quelles actions puis-je mener pour la soulager ?",
    selectMax: 1,
    section: {
      title: "Les comportements à adopter",
      image: "cat-3.jpg",
    },
    answers: [
      {
        label: "Se reposer au lit de manière prolongée",
        value: "Se reposer au lit de manière prolongée",
      },
      {
        label: "En cas de position allongée, surélever les jambes",
        value: "En cas de position allongée, surélever les jambes",
      },
      {
        label: "Consulter un professionnel de santé",
        value: "Consulter un professionnel de santé",
      },
      {
        label: "Rester assis le plus longtemps possible tout mettant ses pieds sur une zone froide pour réduire la sensation d’engourdissement",
        value: "Rester assis le plus longtemps possible tout mettant ses pieds sur une zone froide pour réduire la sensation d’engourdissement",
      },
      {
        label: "Effectuer des étirements (garder une activité)",
        value: "Effectuer des étirements (garder une activité)",
      },
      {
        label: "Faire de la musculation pour traiter le mal par le mal",
        value: "Faire de la musculation pour traiter le mal par le mal",
      },
    ],
  },
  {
    id: 19,
    name: "entry.217353489",
    type: "radio",
    title: "Qu’est-ce que le syndrome du canal carpien ?",
    section: {
      title: "Les comportements à adopter",
      image: "cat-3.jpg",
    },
    answers: [
      {
        label: "Un gonflement important au niveau de la malléole",
        value: "Un gonflement important au niveau de la malléole",
      },
      {
        label: "Une série de syndromes résultant de la compression du nerf au niveau du poignet",
        value: "Une série de syndromes résultant de la compression du nerf au niveau du poignet",
      },
      {
        label: "Une hypersensibilité à la lumière",
        value: "Une hypersensibilité à la lumière",
      },
      {
        label: "Une envie incontrôlable d’aller à la pêche",
        value: "Une envie incontrôlable d’aller à la pêche",
      },
    ],
  },
  {
    id: 20,
    name: "entry.1639709249",
    type: "checkbox",
    title: "Comment s’en prémunir ?",
    section: {
      title: "Les comportements à adopter",
      image: "cat-3.jpg",
    },
    answers: [
      {
        label: "Veiller à ce que ses poignets n’appuient pas sur des surfaces dures durant de longues périodes",
        value: "Veiller à ce que ses poignets n’appuient pas sur des surfaces dures durant de longues périodes",
      },
      {
        label: "Faire rouler une balle de tennis dans la paume de ses mains",
        value: "Faire rouler une balle de tennis dans la paume de ses mains",
      },
      {
        label: "Étirer les avant-bras et les poignets",
        value: "Étirer les avant-bras et les poignets",
      },
      {
        label: "Veiller à tenir les objets à pleine main et non du bout des doigts",
        value: "Veiller à tenir les objets à pleine main et non du bout des doigts",
      },
      {
        label: "Limiter les gestes répétitifs",
        value: "Limiter les gestes répétitifs",
      },
      {
        label: "Masser régulièrement ses dernières phalanges avec des huiles essentielles",
        value: "Masser régulièrement ses dernières phalanges avec des huiles essentielles",
      },
    ],
  },
  {
    id: 21,
    name: "entry.795892411",
    type: "checkbox",
    title: "Sélectionnez les affirmations correctes :",
    section: {
      title: "Les comportements à adopter",
      image: "cat-3.jpg",
    },
    answers: [
      {
        label: "Incliner légèrement son clavier permet de soulager ses poignets",
        value: "Incliner légèrement son clavier permet de soulager ses poignets",
      },
      {
        label: "Une souris verticale permet à la main d’adopter une position naturelle",
        value: "Une souris verticale permet à la main d’adopter une position naturelle",
      },
      {
        label: "Un objet/matériel ergonomique est adapté à tout le monde",
        value: "Un objet/matériel ergonomique est adapté à tout le monde",
      },
      {
        label: "C’est sous le régime de RAMSES II, en Egypte, qu’ont été inventées les électrodes permettant de soulager les douleurs, juste après la découverte de l’électricité",
        value: "C’est sous le régime de RAMSES II, en Egypte, qu’ont été inventées les électrodes permettant de soulager les douleurs, juste après la découverte de l’électricité",
      },
      {
        label: "Les ceintures lombaires médicales ne permettent pas de prévenir les maux de dos",
        value: "Les ceintures lombaires médicales ne permettent pas de prévenir les maux de dos",
      },
    ],
  },
  {
    id: 22,
    type: "end-page",
  },
];

export default questions;
