import joinClasses from "helpers/joinClasses";

export function Textarea({
  className = "",
  onClick = () => {},
  label = "",
  ...props
}) {
  return (
    <label className="block font-raleway-regular leading-[25px]">
      <textarea
        className="w-full min-h-[150px] border border-gray-300 rounded-md p-3 mb-5 focus:outline-primary focus:border-primary"
        onClick={onClick}
        {...props}
      ></textarea>
      {label}
    </label>
  );
}
