
import { useState } from "react";
import "./inputs.css";
import { Radio } from "./radio";
import joinClasses from "helpers/joinClasses";

export function CheckboxImage({
  inputs = [],
  name,
  onChange,
  ...props
}) {

  const [selected, setSelected] = useState([]);

  return (
    <div className={joinClasses("flex flex-col md:flex-row gap-5 md:gap-y-10",
      inputs.length > 2 ? "md:grid md:grid md:grid-cols-3 auto-rows-max" : "md:grid md:grid md:grid-cols-2 auto-rows-max"
    )}>
      {inputs.map((input, index) => (
        <label className={joinClasses("relative rounded-xl  transition-all duration-300 ease-in-out cursor-pointer",
          selected.includes(input.value) ? "border-8 border-primary bg-primary shadow-lg" : "border-8 border-transparent"
        )}>
          <img src={"/assets/questions/" + input.label} alt={input.value} className="w-full h-full object-cover rounded-lg" />

          <input
            className="opacity-0 absolute top-0"
            type="checkbox"
            onChange={(e) => {
              if (e.target.checked) {
                setSelected([...selected, e.target.value])
              } else {
                setSelected(selected.filter((item) => item !== e.target.value))
              }
              onChange(e)
            }}
            value={input.value}
            name={name}
          />
          <p className="mt-4 text-center">
            {input.value}
          </p>
        </label>
      ))}
    </div>
  );
}