export default function Welcome({ onNext = () => { } }) {
  const handleClick = (event) => {
    event.preventDefault();
    onNext();
  };

  return (
    <div className="flex flex-col-reverse
     md:flex-row justify-between w-full w-full min-h-screen">
      <div className="flex flex-col justify-center items-center min-w-2/6 w-full md:max-w-[600px]">
        <div
          style={{
            backgroundImage: `url(/assets/questions/welcome1.jpg)`,
          }}
          className="w-full h-full bg-cover bg-right bg-no-repeat"
        />
        <div className="bg-primary w-full px-12 py-7 text-secondary">
          <h1 className="font-courrier text-4xl border-b border-dotted border-secondary pb-2 mb-3">Troubles<br />Musculo-Squelettiques</h1>
          <p className="font-hurme-bold text-lg">Prévenir les risques en adoptant les bonnes postures</p>
        </div>
        <div
          style={{
            backgroundImage: `url(/assets/questions/welcome2.jpg)`,
          }}
          className="w-full h-full bg-cover bg-right bg-no-repeat"
        />
      </div>

      <div className="min-w-4/6 w-full px-20 py-20 text-secondary">
        <img src="/assets/brand/logo.svg" alt="logo" />
        <div className="h-full flex justify-center items-start flex-col mt-10 md:mt-0">
          <div className="flex justify-center items-center flex-col w-fit border-b border-dotted border-secondary pb-4 mb-5">
            <img src="/assets/brand/quiz-logo.svg" alt="quiz" className="w-[450px]" />
            <h1 className="text-5xl tracking-[15px]	font-bebas-bold uppercase mt-8">Santé Sécurité</h1>
          </div>
          <p className="font-bebas-regular uppercase text-xl tracking-[5px]">DU 02 AU 30 JUIN 2023</p>
          <div className="relative mt-10">
            <div
              className="w-[200px] h-[60px] bg-primary rounded-full"
            />
            <button
              onClick={handleClick}
              className="bg-transparent border border-solid border-secondary tracking-widest text-secondary font-courrier rounded-full flex justify-center items-center w-[200px] h-[60px] absolute top-2 left-1 font-bold transition-all hover:left-0 hover:top-0 text-lg"
            >Participez</button>
          </div>
        </div>
      </div>
    </div>
  );
}
